//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Action from '../../action';
import { includes, isArray, cloneDeep, isString, isObject } from 'lodash';
import { execExpressionDeep } from '../../../../layout/expression';
import { request } from '../../../utils/request';
import { downloadAction } from '../../action/download-action';

const ALLOW_FILE_TYPES = ['csv', 'xls', 'xlsx', 'zip'];
const DEFAULT_FORM = {
  suffix: [],
};

export default {
  mixins: [Action],
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
    expParamsObj: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      form: cloneDeep(DEFAULT_FORM),
      rules: {
        suffix: [{ required: true, message: '请选择格式后导出', trigger: 'change' }],
      },
      visible: false,
      loading: false,
    };
  },
  computed: {
    allowFileTypes() {
      let fileTypes = this.config?.allowTypes;
      if (!fileTypes || !isArray(fileTypes) || !fileTypes.length) {
        fileTypes = ALLOW_FILE_TYPES;
      } else {
        fileTypes = fileTypes.filter((item) => {
          return includes(ALLOW_FILE_TYPES, item);
        });
      }
      return fileTypes;
    },
  },
  methods: {
    open() {
      this.visible = true;
      this.init();
    },
    init() {
      this.$refs.form?.clearValidate();
      this.$nextTick(() => {
        if (this.allowFileTypes.length === 1) {
          this.$set(this.form, 'suffix', [this.allowFileTypes[0]]);
        }
      });
    },
    closeHandler() {
      this.form = cloneDeep(DEFAULT_FORM);
      this.visible = false;
    },
    async confirmHandler() {
      if (!(await this.$refs.form.validate())) {
        return;
      }
      const scopeExpParams = {
        ...this.expParamsObj,
        t: {
          suffix: this.form.suffix,
          p: this.expParamsObj.t,
        },
      };
      const fetchExportUrlReq = execExpressionDeep(
        this.config?.$request_action_callback || {},
        scopeExpParams,
      );
      let resFunc = null;
      if (fetchExportUrlReq.response) {
        resFunc = (scopeData) => {
          return execExpressionDeep(fetchExportUrlReq.response, {
            ...this.expParamsObj,
            t: {
              ...scopeData,
              p: scopeExpParams.t,
            },
          });
        };
      }
      let exportUrlList = [];
      try {
        const res = await this.fetchAndTransExportUrl(
          fetchExportUrlReq.cmd,
          fetchExportUrlReq.payload,
          resFunc,
        );
        if (isArray(res)) {
          exportUrlList = res.filter((item) => isString(item) || isObject(res));
        } else if (isString(res) || isObject(res)) {
          exportUrlList = [res];
        }
        this.$message({
          message: '下载成功',
          type: 'success',
        });
        if (exportUrlList.length) {
          for (let i = 0; i < exportUrlList.length; i++) {
            const item = exportUrlList[i];
            if (isString(item)) {
              window.open(item);
            } else if (isObject(item)) {
              this.downloadField(item);
            }
          }
        }
      } catch (e) {
        console.error(e);
        this.$message.error('下载失败');
      }
      const actionCallback = execExpressionDeep(this.config?.callback || {}, {
        ...this.expParamsObj,
        t: {
          export_urls: exportUrlList,
          p: scopeExpParams.t,
        },
      });
      await this.m_actionHandle(actionCallback);
      this.closeHandler();
    },
    async downloadField(action = {}) {
      const { url, name, private_bucket } = action;
      this.saveExportAction = await downloadAction({
        action_params: {
          url,
          name,
          private_bucket,
        },
      });
      this.saveExportAction?.();
    },
    // 调用云函数获取数据,
    async fetchAndTransExportUrl(cmd, payload = {}, resFunc) {
      const res = await request(cmd, payload);
      if (resFunc) {
        return resFunc(res);
      }
      return res;
    },
  },
};
