//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash';
import sideMenu from './side-menu.vue';
import config from '@/config';
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';
import takeRight from 'lodash/takeRight';
import { GetAppConfig } from 'nges-common/src/web/utils/api';
import { TriggerLoginTerritory } from '@/api/login';
import userAction from '@/components/user-action';
import routerList from '@/router/routes';
import waterMark from 'nges-common/src/web/components/water-mark';
import helpGuide from 'nges-common/src/web/components/help-guide';
import selectConfirmDialog from 'nges-common/src/web/components/action/select-confirm-dialog';

import quickEntry from './quick-entry';
import { breadcrumbCompare } from '@/utils/util';
import shareData from 'nges-common/src/web/components/action/share-popup';
import DataHistoryDrawer from 'nges-common/src/web/components/action/data-history-drawer';
import { isIpadDevice } from '@/utils/platform';
import { getUrlParams } from 'nges-common/src/web-mobile/util.js';
import { WEB_SIDEMENU_COLLAPSE_EVENT } from 'nges-common/src/constant';
import WujieVue from 'wujie-vue2';

const FIRST_HISTORY_STATE_KEY = 'firstkey';

export default {
  name: 'Layout',
  components: {
    sideMenu,
    userAction,
    waterMark,
    quickEntry,
    helpGuide,
    shareData,
    selectConfirmDialog,
    DataHistoryDrawer,
  },
  data() {
    return {
      loading: true,
      // 是否折叠菜单
      isCollapse: false,
      layoutFilter: config.layoutFilter,
      rightFilter: config.rightFilter,
      logo: '',
      collapseLogo: '',
      showNotice: false,
      showTerritoryList: false,
      // 是否右侧展示菜单
      territoryRight: false,
      // 岗位菜单宽度
      // territoryWidth: 0,
      // 全局是否默认显示滚动条
      showPageScrollbar: false,
      // 页面手动设置的面包屑列表
      pageBreadcrumb: [],
      // 根据菜单合操作路径生成的面包屑列表
      menuBreadcrumb: [],
      // 省略一部分的面包屑 仅展示
      overflowBreadcrumb: [],
      // 是否展示返回按钮
      showReturn: false,
      isIpad: false,
      initHistory: false,
    };
  },
  computed: {
    ...mapGetters('user', {
      username: 'getName',
      storePageBreadcrumb: 'getPageBreadcrumb',
      territoryList: 'getTerritoryList',
      territoryCode: 'getCurrentTerritory',
      territoryType: 'getCurrentTerritoryType',
      userInfo: 'getUserInfo',
      headimg: 'getHeadimg',
    }),
    ...mapGetters('config', ['getConfig']),
    ...mapState('user', {
      hasRight: 'hasRight',
    }),
    menuFooterStyle() {
      // 吸底要设置宽度
      return !this.isCollapse
        ? {
            textAlign: 'right',
            width: '232px',
          }
        : {
            textAlign: 'center',
            width: this.isIpad ? '80px' : '56px',
          };
    },
    fullName() {
      if (this.username && this.territoryList && this.territoryList.length && this.territoryCode) {
        const userName =
          this.username.length > 15 ? `${this.username.slice(0, 15)}...` : this.username;
        const territory = this.territoryList.filter(({ code }) => code === this.territoryCode);

        if (territory.length) {
          const territoryName =
            territory[0].name.length > 30
              ? `${territory[0].name.slice(0, 30)}...`
              : territory[0].name;
          return `${userName}-${territoryName}`;
        }
        return userName;
      }
      if (this.username) {
        return this.username.length > 15 ? `${this.username.slice(0, 15)}...` : this.username;
      }
      return '';
    },
    breadcrumb() {
      return this.pageBreadcrumb.length ? this.pageBreadcrumb : this.menuBreadcrumb;
    },
    showBreadcrumb() {
      return this.overflowBreadcrumb.length ? this.overflowBreadcrumb : this.breadcrumb;
    },
    // 是否需要展示返回
    hasBreadcrumbBack() {
      // 至少存在两个面包屑
      if (this.breadcrumb.length < 1) return false;
      // 同时除最后一个面包屑外至少有一个面包屑存在对应的路径
      for (let i = this.breadcrumb.length - 2; i >= 0; i -= 1) {
        if (this.getBreadcrumbToUrl(this.breadcrumb[i]) !== '') {
          return this.breadcrumb[i];
        }
      }
      return false;
    },
  },
  watch: {
    $route(newv, oldv) {
      // 记录当前路由的key用来判断是否点击了浏览器的后退
      // 屏蔽进入时加载状态下的第一次触发
      if (!this.initHistory) {
        this.initHistory = true;
      } else {
        this.updateHistoryList();
      }
      if (oldv?.name) this.updateLastBreadcrumb(oldv);
      // 需要让popstate先于这里执行 在从登录页进入时会存在popstate后执行的情况
      setTimeout(() => {
        this.updateBreadcrumb();
      }, 500);
    },
    storePageBreadcrumb(newval) {
      if (newval.length) {
        this.pageBreadcrumb = newval;
        this.setBreadcrumb([]);
      }
    },
    breadcrumb(newVal) {
      this.overflowBreadcrumb = [];
      this.showReturn = false;
      this.setBreadcrumbLayout();
      this.setResultBreadcrumb(newVal);
    },
    isCollapse() {
      WujieVue.bus.$emit(WEB_SIDEMENU_COLLAPSE_EVENT);
    },
  },
  created() {
    this.setDomain();
    this.getManagerLogin();
    this.getPublishedAppList();
    this.GetUserMenuTree().then(() => {
      this.loading = false;
      this.updateBreadcrumb(true);
      this.getLayoutJsonWithPath('globalConfigWeb');
    });
    this.getStaffUserInfo().then(() => {
      // 等待组件获取岗位数据
      setTimeout(() => {
        // 默认自动换行 手动计算当前菜单宽度
        const itemWidth = 360;
        // Math.max(Math.min(...this.territoryList.map(({ name }) => name.length), 25), 12) * 14;
        // this.territoryWidth = itemWidth;
        // 岗位菜单宽度+下拉菜单宽度
        const menuWidth = itemWidth + 24 + 220;
        if ((this.$refs.userInfo?.clientWidth || 0) > menuWidth) this.territoryRight = true;
        else this.territoryRight = false;
      }, 1000);
    });
    this.requestGlobalConfig();
    this.requestAppConfig();
    this.isIpad = isIpadDevice();
  },
  mounted() {
    this.getAppConf();
    this.addWinListener();
    window.addEventListener('popstate', this.checkGoBack);
  },
  beforeDestroy() {
    this.removeListenWinResize && this.removeListenWinResize();
    window.removeEventListener('popstate', this.checkGoBack);
  },
  methods: {
    ...mapGetters('user', ['getLayoutJsonWithPath', 'getMenuBreadcrumb']),
    ...mapActions('user', [
      'getManagerLogin',
      'GetUserMenuTree',
      'getPublishedAppList',
      'requestMessageCount',
      'getStaffUserInfo',
    ]),
    ...mapActions('config', ['requestGlobalConfig']),
    ...mapActions('appConfig', ['requestAppConfig']),
    ...mapMutations('user', ['setBreadcrumb', 'setResultBreadcrumb']),
    setDomain() {
      const { hostname } = location;
      if (hostname.includes('localhost')) {
        return;
      }
      const rootDomain = takeRight(hostname.split('.'), 2).join('.');
      console.log('rootDomain', rootDomain);
      // document.domain = rootDomain;
    },
    async getAppConf() {
      let logo = '';
      let collapseLogo = '';
      let showPageScrollbar = false;
      try {
        const data = await GetAppConfig();
        logo = data?.web_logo;
        // 菜单收缩后的 logo
        collapseLogo = data?.page_config?.web_collapse_logo || '';
        showPageScrollbar = data?.page_config?.show_page_scrollbar == '1';
      } catch (e) {}
      this.logo = logo || this.g_imgSrc.logo;
      this.collapseLogo = collapseLogo || '';
      this.showPageScrollbar = showPageScrollbar;
    },
    changeTerritory({ code, relation_type: relationType = '' }) {
      this.showTerritoryList = false;
      if (code === this.territoryCode && relationType === this.territoryType) return;
      TriggerLoginTerritory({ territory_code: code, territory_type: relationType }).then(() => {
        location.replace('/');
        // location.reload();
      });
    },
    getBreadcrumbToUrl(item, index) {
      if (index === this.showBreadcrumb.length - 1) return;
      if (item.menu_path) {
        if (item.menu_path.indexOf('custom_') > -1) {
          if (item.menu_params?.custom) {
            return {
              name: 'custom',
              query: {
                ...item.menu_params,
                custom: item.menu_params?.custom,
              },
            };
          }
          return { name: 'custom', query: { custom: item.menu_path } };
        }
        // 如果是路径通过path跳转
        if (item.menu_path?.startsWith('/')) {
          let query = item.menu_params;
          // 最后一个面包屑补全参数，有些参数会在跳转的时候加到url上
          if (
            index === this.breadcrumb.length - 1 &&
            this.$route.path === item.menu_path?.split('?')?.[0]
          ) {
            query = {
              ...query,
              ...this.$route.query,
            };
          }
          return { path: item.menu_path, query: query || {} };
        }

        if (routerList.find(({ name }) => item.menu_path === name)) {
          return { name: item.menu_path, query: item.menu_params || {} };
        }
      }
      return '';
    },
    // 页面加载完成或切换时手动更新面包屑
    updateBreadcrumb(isInit = false) {
      if (this.loading) return;
      const menuBreadcrumb = this.getMenuBreadcrumb();
      if (menuBreadcrumb.length === 0) return;
      // 上一个页面手动设置了面包屑的场合 将这个面包屑保留下来
      // 初始化加载时不存在上一个页面 当此时页面已经设置了面包屑时 直接使用已经设置的面包屑 跳过后续步骤
      if (this.pageBreadcrumb.length) {
        if (isInit) return;
        this.menuBreadcrumb = this.pageBreadcrumb;
      }
      this.pageBreadcrumb = [];

      // 如果是第一次进入页面就直接生成当前菜单的面包屑
      if (this.menuBreadcrumb.length === 0) {
        const query = menuBreadcrumb[menuBreadcrumb.length - 1].menu_params || {};
        // 第一次进入时$route可能取不到值 手动将menu_path的query取出
        let pathQuery = {};
        if (isInit) {
          pathQuery = getUrlParams(menuBreadcrumb[menuBreadcrumb.length - 1].menu_path);
        }
        menuBreadcrumb[menuBreadcrumb.length - 1].menu_params = {
          ...query,
          ...pathQuery,
          ...this.$route.query,
        };
        this.menuBreadcrumb = menuBreadcrumb;
      } else {
        // 操作跳转到其他页面时 把当前菜单的面包屑叠加到末尾
        // 如果当前菜单已经在面包屑列表里了 那么删掉其后面所有的面包屑
        const now = menuBreadcrumb[menuBreadcrumb.length - 1];
        if (!_.isEmpty(this.$route.query)) {
          now.menu_params = {
            ...this.$route.query,
          };
        }
        this.menuBreadcrumb = breadcrumbCompare(this.menuBreadcrumb, now);
      }
    },
    // 点击了左侧菜单的场合 清空所有的面包屑数据
    clearBreadcrumb() {
      this.menuBreadcrumb = [];
      this.pageBreadcrumb = [];
    },
    // 页面跳走前将当前页面最新的参数更新到面包屑上
    updateLastBreadcrumb({ query }) {
      if (this.menuBreadcrumb.length) {
        this.menuBreadcrumb[this.menuBreadcrumb.length - 1].menu_params = { ...(query || {}) };
      }
    },
    breadcrumbBack() {
      this.$router.go(-1);
    },
    setBreadcrumbLayout: _.debounce(async function () {
      if (!this.$refs.breadcrumb) return;
      // 如果宽度超标 舍弃倒数第二个面包屑
      if (this.$refs.breadcrumb.$el.scrollWidth > this.$refs.breadcrumb.$el.clientWidth) {
        if (this.overflowBreadcrumb.length) {
          if (this.overflowBreadcrumb.length < 3) return;
          this.overflowBreadcrumb = [
            ...this.overflowBreadcrumb.slice(0, -3),
            { menu_name: '...' },
            this.overflowBreadcrumb[this.overflowBreadcrumb.length - 1],
          ];
          this.setBreadcrumbLayout();
        } else {
          if (this.breadcrumb.length < 3) return;
          // 第一次舍弃面包屑时出现返回按钮
          this.showReturn = true;
          await this.$nextTick();
          this.overflowBreadcrumb = [
            ...this.breadcrumb.slice(0, -2),
            { menu_name: '...' },
            this.breadcrumb[this.breadcrumb.length - 1],
          ];
          this.setBreadcrumbLayout();
        }
      }
    }, 100),
    // 监听界面变化
    addWinListener() {
      const isIpad = isIpadDevice();
      // 竖屏检测查询
      const mediaQuery = window.matchMedia('(orientation: portrait)');
      if (isIpad && mediaQuery.matches) {
        this.isCollapse = true;
      }
      if (_.isFunction(this.removeListenWinResize)) {
        this.removeListenWinResize();
      }
      const func = _.debounce(() => {
        this.overflowBreadcrumb = [];
        this.setBreadcrumbLayout();
      }, 100);
      const mediaQueryFunc = _.debounce((e) => {
        if (!isIpad) return;
        if (e.matches) {
          this.isCollapse = true;
        } else {
          this.isCollapse = false;
        }
      }, 100);
      window.addEventListener('resize', func);
      mediaQuery.addEventListener('change', mediaQueryFunc);
      this.removeListenWinResize = () => {
        window.removeEventListener('resize', func);
        mediaQuery.removeEventListener('change', mediaQueryFunc);
      };
    },
    // 点击后退时面包屑长度只有1的场合 视为从一个一级页面离开到其他页面 清除当前面包屑
    checkGoBack() {
      setTimeout(() => {
        let key = history.state?.key;
        if (!key) key = FIRST_HISTORY_STATE_KEY;
        if (!key) return;
        const list = this.getHistoryList();
        const index = list.findIndex((i) => i === key);
        if (index !== -1 && this.breadcrumb.length === 1) {
          this.clearBreadcrumb();
        }
      }, 50);
    },
    getHistoryList() {
      let list = [];
      try {
        list = JSON.parse(sessionStorage.getItem('nges_history_List') || '[]');
      } catch (e) {
        console.log(e);
        list = [];
      }
      return list;
    },
    setHistoryList(list) {
      const preList = this.getHistoryList();
      // 对第一个页面加入默认值
      if (!preList.length && list.length === 1) {
        sessionStorage.setItem(
          'nges_history_List',
          JSON.stringify([FIRST_HISTORY_STATE_KEY, ...list]),
        );
      } else {
        sessionStorage.setItem('nges_history_List', JSON.stringify(list));
      }
    },
    // 堆叠history的key 如果点击返回要把后面的key都去掉
    updateHistoryList() {
      const key = history.state?.key || FIRST_HISTORY_STATE_KEY;
      if (!key) return;
      const list = this.getHistoryList();
      const index = list.findIndex((i) => i === key);
      if (index !== -1) {
        this.setHistoryList(list.slice(0, index + 1));
      } else {
        list.push(key);
        this.setHistoryList(list);
      }
    },
    isIpadDevice() {
      const userAgent = navigator.userAgent.toLowerCase();
      const isMac = userAgent.indexOf('macintosh') > -1;
      const isIOS = /ip(ad|od)/.test(userAgent);

      if (isMac && 'ontouchend' in document) {
        // 对于 iPadOS 13 及更高版本，User-Agent 字符串包含 "Macintosh"，并且设备支持触摸事件
        return true;
      }
      if (isIOS) {
        // 对于 iPadOS 12 及更低版本，User-Agent 字符串包含 "iPad"、"iPhone" 或 "iPod"
        return true;
      }
      return false;
    },
  },
};
