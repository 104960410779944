//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DetailContent from './detail-content';
import { mapGetters } from 'vuex';
import { get } from 'lodash';
import Action from '../../../action';
import ngesDialog from 'nges-common/src/web/components/layout/dialog';
import { getCustomJson, getGlobalConfigGlobalFunc } from 'nges-common/src/layout/queryWeb';
import { watermarkUtil } from 'nges-common/src/web/components/water-mark/util';
import MemoryStoreMixin from '../../../../../components/memory-store/memory-store-mixin';
import { execExpressionDeep } from '../../../../../layout/expression';
import confirmDialog from 'nges-common/src/web/components/action/confirm-dialog/index.vue';

const _ = { get };

export default {
  name: 'LayoutDetail',
  components: {
    DetailContent,
    ngesDialog,
    confirmDialog,
    detailDialog: () => import('nges-common/src/web/components/action/detail-dialog/index.vue'),
  },
  mixins: [Action, MemoryStoreMixin],
  props: {
    json: {
      type: Object,
      default() {
        return null;
      },
    },
    layout: {
      type: String,
      default: '',
    },
    targetId: {
      type: String,
      default() {
        return '';
      },
    },
    params: {
      type: Object,
      default() {
        return {};
      },
    },
    btnLoading: {
      type: Boolean,
      default: false,
    },
    parentData: {
      type: Object,
      default() {
        return {};
      },
    },
    hideLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      detailJson: null,
      containerTitle: '',
      visible: false,
      forceContainer: false,
      validateObj: null,
      formValue: {},
      dcrInfo: {},
    };
  },
  computed: {
    ...mapGetters('user', {
      layoutJson: 'getLayoutJson',
    }),
    showType() {
      const renderType = this.detailJson?.render?.type;
      if (this.forceContainer && renderType !== 'dialog' && renderType !== 'drawer') {
        return 'drawer';
      }
      return renderType;
    },
    style() {
      return this.detailJson?.render?.style || {};
    },
    tags() {
      return execExpressionDeep(this.detailJson?.title?.tags, this.expParamsObj) || [];
    },
    expParamsObj() {
      return this.memStoreSet({
        t: {
          id: this.targetId,
          ...this.formValue,
          dcrInfo: this.memStoreGet(this.dcrInfo),
        },
        p: {
          ...this.params,
        },
        json: this.json,
      });
    },
  },
  watch: {
    json() {
      this.getJson();
    },
    layoutJson() {
      this.getJson();
    },
    visible(nv, ov) {
      if (!nv && nv !== ov) {
        watermarkUtil.reloadWatermark();
      }
    },
  },
  async mounted() {
    await getGlobalConfigGlobalFunc(this);
    this.getJson();
  },
  methods: {
    execExpression(obj) {
      const result = execExpressionDeep(obj, this.expParamsObj);
      return result;
    },
    reload() {
      this.$refs?.detail.reload();
    },
    setContainerTitle(title) {
      this.containerTitle = title;
    },
    open() {
      this.forceContainer = true;
      this.$nextTick(() => {
        this.visible = true;
        this.$refs.detail?.reload();
      });
    },
    handleFetchData(value) {
      console.log('handleFetchData', value);
      this.$emit('fetched-data', value);
      this.formValue = value;
    },
    handleFetchDcrInfo(value) {
      this.dcrInfo = value;
    },
    async getJson() {
      const json = this.json ? this.json : this.layoutJson;
      const detailJson = this.layout ? _.get(json, this.layout) : json;
      this.setContainerTitle(detailJson.title?.text || detailJson.title);
      this.$emit('set-title', detailJson.title);
      this.detailJson = await getCustomJson(detailJson);
    },
    m_customAction(action) {
      if (action?.action === 'DETAIL_CLOSE') {
        this.visible = false;
      }
      return action;
    },
  },
};
