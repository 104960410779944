//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { isEmpty, get } from 'lodash';
import ngesDialog from 'nges-common/src/web/components/layout/dialog';
import { getCustomJson, getGlobalConfigGlobalFunc } from 'nges-common/src/layout/queryWeb';
import { watermarkUtil } from 'nges-common/src/web/components/water-mark/util';
import { execExpression } from '../../../../layout/expression';
import { WEB_SIDEMENU_COLLAPSE_EVENT } from 'nges-common/src/constant';

const _ = { isEmpty, get };

const INIT_DATA = {
  visible: false,
  layout: {},
  isClose: Promise.resolve(),
};
export default {
  name: 'MutationLayout',
  components: {
    Mutation: () => import('./components/mutation'),
    ngesDialog,
  },
  props: {
    // 布局json，与其他布局使用一样的prop
    json: {
      type: Object,
      default: () => {},
    },
    customMutationPath: {
      type: String,
      default: '',
    },
    beforeSubmit: {
      type: Function,
      default: null,
    },
    params: {
      type: Object,
      default: () => {},
    },
    mutationFormData: {
      type: [Object, null],
    },
    lifeCycle: {
      type: Object,
    },
    isInit: {
      type: Boolean,
      default: false,
    },
    currentStep: {
      type: Number,
      default: -1,
    },
    parentData: {
      type: [Object, null],
      default: () => null,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    canUseQueryId: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      ...INIT_DATA,
      trkSaved: () => {},
      openedCacheFunc: () => {},
    };
  },
  computed: {
    ...mapGetters('user', {
      remoteLayoutJson: 'getLayoutJson',
    }),
    containerTitle() {
      return execExpression(this.layout.title || this.layout.layoutName, {
        p: this.params,
        json: this.layout,
      });
    },
    showType() {
      const layout = !_.isEmpty(this.json) ? this.json : this.layout;
      const renderType = layout?.render?.type;
      return renderType === undefined ? 'drawer' : renderType;
    },
    isFixed() {
      const layout = !_.isEmpty(this.json) ? this.json : this.layout;
      const actionsBarFixed = layout?.render?.actions_bar_fixed;
      return actionsBarFixed === undefined ? true : actionsBarFixed;
    },
    style() {
      const layout = !_.isEmpty(this.json) ? this.json : this.layout;
      return layout?.render?.style || {};
    },
    // 兼容以前可能存在的纯数字型字符串
    drawerSize() {
      const width = this.style?.width;
      if (!width) return '30%';
      // 直接输入相对宽度
      if (typeof this.style.width === 'string' && width.includes('%')) {
        return width;
      }
      return parseInt(this.style.width, 10);
    },
  },
  watch: {
    isFixed() {
      this.resetChangeWidthListener();
    },
    showType() {
      this.resetChangeWidthListener();
    },
  },
  async mounted() {
    this.resetChangeWidthListener();
    await getGlobalConfigGlobalFunc(this);
    if (this.isInit) {
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.open({
            id: this.canUseQueryId ? this.$route?.query?.id : '',
          });
        });
      });
    }
    this.$nextTick(() => {
      this.trkSaved = this.$refs.mutation?.__trkSaved || (() => {});
    });
  },
  beforeDestroy() {
    if (window.$wujie) {
      window.$wujie?.bus.$off(WEB_SIDEMENU_COLLAPSE_EVENT, this.changeWidth);
    }
  },
  methods: {
    validateForm() {
      console.log('validate form');
      this.$refs.mutation.rulesValidate();
    },
    handleAction({ action, ...rest }) {
      // 向外部组件emit自定义action事件
      this.$emit('action-handle', {
        action_params: {
          action,
          ...rest,
        },
        action,
        ...rest,
      });
    },
    componentMounted(data) {
      this.$emit('component-mounted', data);
    },
    async open({ layout, id = '', listRef, json }) {
      let rawLayout;
      if (!_.isEmpty(json)) {
        rawLayout = json;
      } else if (!_.isEmpty(this.json)) {
        rawLayout = this.json;
      } else {
        rawLayout = _.get(this.remoteLayoutJson, layout);
      }
      this.layout = await getCustomJson(rawLayout);

      if (this.showType === 'drawer' || this.showType === 'dialog') {
        await this.isClose;
        this.visible = true;
      }
      const openFunc = () => {
        this.$nextTick(() => {
          this.$refs?.mutation?.open({ layout, id, listRef, json });
        });
      };
      if (this.showType === 'drawer' || this.showType === 'dialog') {
        this.openedCacheFunc = openFunc;
      } else {
        openFunc();
      }
    },
    opened() {
      this.openedCacheFunc();
    },
    close() {
      let resolve;
      this.isClose = new Promise((re) => {
        resolve = re;
      });
      this.timer && clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.visible = false;
        watermarkUtil.reloadWatermark();
        resolve();
        resolve = null;
      }, 200);
      this.$emit('close');
      // Object.keys(INIT_DATA).forEach((key) => {
      //   this[key] = INIT_DATA[key];
      // });
    },
    actionHandle(action) {
      this.$emit('action-handle', action);
    },
    mergeForm(args) {
      this.$refs.mutation.mergeForm(args);
    },
    getGraphqlMutationStr() {
      return this.$refs.mutation.getGraphqlMutationStr();
    },
    handleSetValidate(obj) {
      if (this.$refs.mutation) {
        if (obj?.queryTipsConf?.length) {
          this.$refs.mutation.validateObj = obj.validateObj;
        }
      }
    },
    __trkSaved() {
      this.trkSaved?.();
    },
    // 在ipad内如果用定开页面展示底部按钮栏时，左侧菜单折叠后宽度未重新计算，需要手动重置宽度
    resetChangeWidthListener() {
      if (this.isFixed && !(this.showType === 'drawer' || this.showType === 'dialog')) {
        if (window.$wujie) {
          window.$wujie?.bus.$off(WEB_SIDEMENU_COLLAPSE_EVENT, this.changeWidth);
          window.$wujie?.bus.$on(WEB_SIDEMENU_COLLAPSE_EVENT, this.changeWidth);
        }
      }
    },
    changeWidth() {
      const footerEl = this.$refs.mutation.$refs['action-footer'];
      footerEl.refreshFlag = false;
      this.$nextTick(() => {
        footerEl.refreshFlag = true;
      });
    },
  },
};
