import { ERROR_BIZCODE_LIST, GQL_SPECIAL_CHAR } from '@/utils/constant';
import { gotoUrl } from '@/libs/utils/util';
import _ from 'lodash';
import { GetKvConfigs } from 'nges-common/src/web/utils/api';
/**
 * 阿拉伯号转中字
 * @param {Number} num 数字
 */
const getChinese = (num) => {
  const changeNum = ['', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
  const unit = ['', '十', '二十', '三十', '四十', '五十', '六十', '七十', '八十', '九十', '一百'];
  const newNum = parseInt(num, 10);
  let res = num;
  if (newNum >= 10) {
    const a = String(num)[0];
    const b = String(num)[1];
    res = unit[a] + changeNum[b];
  } else {
    res = changeNum[res];
  }

  return res;
};

/**
 * 数组去重
 * @param {*} arr 接收的原数组
 * @param {*} key 如果是对象数组[{id: 1}, {id: 2}, {id: 3}]，则需要以什么key作为重复的标准，普通数组[1,2,3,2]不需要
 */
const arrUnique = function (arr, key) {
  let returnArr = [];
  if (key) {
    // 对象数组去重
    const obj = {};
    returnArr = arr.reduce((cur, next) => {
      obj[next[key]] ? '' : (obj[next[key]] = true && cur.push(next));
      return cur;
    }, []);
    return returnArr;
  }
  // 普通数组去重
  returnArr = arr.reduce((cur, next) => {
    !cur.includes(next) && cur.push(next);
    return cur;
  }, []);
  return returnArr;
};
const loadJs = function (options = {}) {
  const { url, onload, onerror, retry } = options;
  const el = document.createElement('script');
  el.src = url;
  if (typeof onload === 'function') {
    el.onload = onload;
  }
  el.onerror = function (...args) {
    if (typeof onerror === 'function') {
      onerror.call(this, ...args);
    }
    if (retry > 0) {
      loadJs({ url, onload, onerror, retry: retry - 1 });
    }
  };
  document.body.appendChild(el);
};
const mapQuery = function (query) {
  if (Object.prototype.toString.call(query) === '[object Object]') {
    let paramsStr = '';
    // for (const key in query) {
    //   const value = query[key];
    //   if (value !== undefined && value !== null && value !== '') {
    //     paramsStr += `&${key}=${value}`;
    //   }
    // }
    Object.keys(query).forEach((key) => {
      const value = query[key];
      if (value !== undefined && value !== null && value !== '') {
        paramsStr += `&${key}=${value}`;
      }
    });
    return paramsStr.replace('&', '?');
  }
};
const gotoHome = async function () {
  try {
    const homePath = _.get(await GetKvConfigs('["web_home_path"]'), 'kv[0].value');
    if (homePath) {
      gotoUrl(homePath, {
        replace: true,
        href: true,
      });
    } else {
      gotoUrl(`/saas/articleManagement.html?timestamp=${new Date().getTime()}`, {
        replace: true,
        href: true,
      });
    }
  } catch (e) {
    console.log(e);
    gotoUrl(`/saas/articleManagement.html?timestamp=${new Date().getTime()}`, {
      replace: true,
      href: true,
    });
  }
  return;
};

const formatSecondsToMin = (seconds) => {
  if (!seconds) return 0;
  const min = parseInt(seconds / 60, 10);
  const s = seconds % 60;
  return `${min ? `${min}min` : ''} ${s ? `${s}s` : ''}`;
};
const formatMinsToHour = (mins) => {
  if (!mins) return 0;
  const hour = parseInt(mins / 60, 10);
  const min = mins % 60;
  return `${hour ? `${hour}hour` : ''} ${min ? `${min}min` : ''}`;
};
/**
 * 等待值
 * @param {*} key key名
 * @param {*} that 对象 this
 * @param {*} total 等待次数
 */
const awaitInterfaceResponse = (key, that, total) => {
  return new Promise((resolve, reject) => {
    let time = 1;
    let interval = 100;
    const totalTime = total || 10;
    const pollingValue = () => {
      // 先判断有没有值
      const value = that ? that[key] : key;
      if (value) {
        resolve();
      } else {
        console.log(time);
        if (time < totalTime) {
          interval += 100;
          time += 1;
          setTimeout(() => {
            pollingValue();
          }, interval);
        } else {
          reject();
        }
      }
    };
    pollingValue();
  });
};

/** 文件名后缀切割
 * @description: 传入包含后缀的文件名
 * @param {String} value eg: 前端进阶指南.png
 * @return {Object} { prefix, postfix } 前缀 后缀
 */
const postfixFun = (value) => {
  const temp = value;
  const arr = temp.split('.');
  const postfix = `.${arr[arr.length - 1]}`; // 后缀
  const prefix = temp.replace(postfix, ''); // 前缀
  return { prefix, postfix };
};

const bytesToSize = (bytes) => {
  if (`${bytes}` === '0') return '0 B';
  const k = 1024; // or 1024
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  // 当超过1000时改成toPrecision（4）防止变成 指数形式 =>1.02e+3
  if (bytes / k ** i >= 1000) {
    return `${(bytes / k ** i).toPrecision(4)}${sizes[i]}`;
  }

  return `${(bytes / k ** i).toPrecision(3)}${sizes[i]}`;
};

const saveAs = (blob, fileName) => {
  const link = document.createElement('a');
  if ('download' in link) {
    link.href = window.URL.createObjectURL(blob);
    link.style.display = 'none';
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    // 对于不支持 download 属性的浏览器（如 IE），使用 window.navigator.msSaveOrOpenBlob 函数
    window.navigator.msSaveOrOpenBlob(blob, fileName);
  }
};

const downloadFileAction = (
  urltemp,
  fileName,
  onprogress,
  asyncDownloadBigFile,
  manualSaveNeeded,
) => {
  return new Promise(async (resolve, reject) => {
    // const urltemp =
    //   'https://tsep-test-1304685099.cos.ap-guangzhou.myqcloud.com/tsep_dev/11501202/2fbd5f8d-ecae-471c-8856-d53624e06e72';
    const url2 = urltemp.replace(/\\/g, '/');
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url2, true);
    xhr.responseType = 'blob';
    // xhr.setRequestHeader('Authorization', 'Basic a2VybWl0Omtlcm1pdA==');
    // 为了避免大文件影响用户体验，建议加loading
    xhr.onload = () => {
      if (xhr.status === 200) {
        // 获取文件blob数据并保存
        console.log(xhr);
        console.log(xhr.response);
        // manualSaveNeeded: 根据需求侧需要，自行开发弹窗时，自定义保存按钮点击使用. 建议asyncDownloadBigFile: false时使用
        if (!manualSaveNeeded) {
          saveAs(new Blob([xhr.response]), fileName);
        }
        resolve(
          manualSaveNeeded
            ? () => {
                return saveAs(new Blob([xhr.response]), fileName);
              }
            : true,
        );
        return;
      }
      reject(false);
    };
    xhr.onprogress = (progress) => {
      /**
       * 对于大于100m的文件采用window.open打开，避免出现内存溢出问题，当然该url也可能不是文件流，这个时候就会直接打开文件，而不是下载
       * 可以优化大文件下载流程，譬如借助indexdb做断点下载或者server-worker做后台下载，都比较复杂，建议后续有需要建需求完善
       */
      if (asyncDownloadBigFile && progress.total > 100 * 1024 * 1024) {
        xhr.abort();
        window.open(url2);
        return resolve(true);
      }
      onprogress && onprogress(progress);
    };
    xhr.send();
  });
};

const dateFormat = (fmt, date) => {
  const o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds(), // 毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, `${date.getFullYear()}`.substr(4 - RegExp.$1.length));
  }
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : `00${o[k]}`.substr(`${o[k]}`.length),
      );
    }
  }
  return fmt;
};

function downloadAndRename(url, name) {
  function getBlob(url) {
    return new Promise((resolve) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);

      xhr.responseType = 'blob';
      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(xhr.response);
        }
      };

      xhr.send();
    });
  }
  function saveAs(blob, filename) {
    // ie的下载
    if (window.navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, filename);
    } else {
      // 非ie的下载
      const link = document.createElement('a');
      const body = document.querySelector('body');

      link.href = window.URL.createObjectURL(blob);
      link.download = filename;

      // fix Firefox
      link.style.display = 'none';
      body.appendChild(link);

      link.click();
      body.removeChild(link);

      window.URL.revokeObjectURL(link.href);
    }
  }
  return getBlob(url).then((blob) => {
    saveAs(blob, name);
  });
}

// 类型判断
function toStringCall(obj) {
  const map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object',
  };
  return map[window.Object.prototype.toString.call(obj)];
}

function handleError(e) {
  console.log(e);
  if (e.bizcode) return ERROR_BIZCODE_LIST[e.bizcode];
  return e.message;
}

function gqlCharReplace(str) {
  let string = str;
  GQL_SPECIAL_CHAR.forEach((char) => {
    string = string.replace(new RegExp(char, 'g'), `\\\\${char}`);
  });
  return string;
}

// handle html escape character
function escape(input) {
  const output = input;
  return output.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
}

function breadcrumbCompare(list, last) {
  const breadcrumbList = list;
  let indexList = [];
  breadcrumbList.forEach((i, j) => {
    if (i.menu_path === last.menu_path) indexList.push(j);
  });
  indexList = indexList.reverse();
  if (indexList.length === 0) {
    breadcrumbList.push(last);
    return breadcrumbList;
  }
  // 从最后一个符合情况的开始判断
  const same = indexList.find((i) => {
    return isSameBread(breadcrumbList[i], last);
  });
  if (same !== undefined) {
    return list.slice(0, same + 1);
  }
  breadcrumbList.push(last);
  return breadcrumbList;
}

function isSameBread(a, b) {
  if (_.isEmpty(a.menu_params) && _.isEmpty(b.menu_params)) {
    // 都没有参数则为一致
    return true;
  }
  const newAimParam = _.cloneDeep(a.menu_params || {});
  const newNowParam = _.cloneDeep(b.menu_params || {});
  // 以下参数的差异不构成页面的不同 忽略掉
  Object.keys(newAimParam).forEach((key) => {
    if (!paramIsBreadcrumb(key)) {
      delete newAimParam[key];
    }
  });
  Object.keys(newNowParam).forEach((key) => {
    if (!paramIsBreadcrumb(key)) {
      delete newNowParam[key];
    }
  });

  // 有参数则看深度比较的结果
  return _.isEqual(newAimParam, newNowParam);
}

function downloadFileFromBase64(base64Data, fileName = 'Untitled') {
  const byteCharacters = atob(base64Data);
  const byteArrays = [];
  const sliceSize = 512;

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: 'application/octet-stream' });
  saveAs(blob, fileName);
}

// 参数key不在黑名单里的话就要作为面包屑的参数
const paramIsBreadcrumb = function (key) {
  const blackList = ['tab_index', 'tab_key', 'wujie'];
  return !(blackList.includes(key) || key.startsWith('emit_after'));
};

export {
  gotoUrl,
  getChinese,
  arrUnique,
  loadJs,
  mapQuery,
  gotoHome,
  formatSecondsToMin,
  formatMinsToHour,
  awaitInterfaceResponse,
  postfixFun,
  bytesToSize,
  downloadFileAction,
  dateFormat,
  downloadAndRename,
  toStringCall,
  handleError,
  gqlCharReplace,
  escape,
  breadcrumbCompare,
  downloadFileFromBase64,
  paramIsBreadcrumb,
};
