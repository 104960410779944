import { get, set, isNumber, isString, isFunction } from 'lodash';
const memStore = {};

let increIdx = 0;

function setData(data, scopeId, key) {
  scopeId = scopeId ?? this.scopeId;
  if (isNumber(scopeId) || isString(scopeId)) {
    increIdx = increIdx + 1;
    set(memStore, `scopeId${scopeId}.id${key || increIdx}`, data);
    return String(increIdx);
  }
  return data;
}

function getData(key, scopeId) {
  scopeId = scopeId ?? this.scopeId;
  if ((isNumber(key) || isString(key)) && (isNumber(scopeId) || isString(scopeId))) {
    return get(memStore, `scopeId${scopeId}.id${key}`);
  }
  if (isFunction(key)) {
    return key();
  }
  return key;
}

function clearData(scopeId) {
  if (isNumber(scopeId) || isString(scopeId)) {
    memStore[`scopeId${scopeId}`] = undefined;
    delete memStore[`scopeId${scopeId}`];
  }
}

export default {
  set: setData,
  get: getData,
  clear: clearData,
  handler: {
    memStoreSet: setData,
    memStoreGet: getData,
    memStoreClear: clearData,
  },
};
