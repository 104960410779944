/**
 * 该文件提供给worker线程和UI线程使用
 * 所以仅能引入纯函数，不能引入跟宿主环境相关的全局变量，例如window，navigator，wx等对象
 */
import {
  get,
  isArray,
  cloneDeep,
  uniq,
  isPlainObject,
  forEach,
  isString,
  isNumber,
  map,
  isObject,
} from 'lodash';
import { formatTime } from '@tencent/eyao-util-core';
import { PROP_SPLIT, PROP_LABEL, PROP_CLASS, FORMAT_TIME } from '../constant';

// 获取所有的对象数据
function getObjectsFields(json) {
  const fields = cloneDeep(json?.fields || []);
  const object = json?.object;
  const alias = json?.alias || {};
  const objectsFields = { [object]: fields };
  fields.forEach((field) => {
    const arr = field.split('.');
    if (arr.length > 1) {
      for (let i = 0; i < arr.length - 1; i++) {
        const objectName = alias[arr[i]] || arr[i];
        if (!objectsFields[objectName]) {
          objectsFields[objectName] = [];
        }
        objectsFields[objectName].push(arr.slice(i + 1).join('.'));
      }
    }
  });
  for (const k of Object.keys(objectsFields)) {
    objectsFields[k] = uniq(objectsFields[k]);
  }
  return objectsFields;
}

function doTransObjectValue({
  data,
  objectsFields,
  objectsMap,
  alias,
  objects = {},
  simpleObjects = [],
}) {
  if (isPlainObject(data)) {
    for (const k of Object.keys(data)) {
      const value = data[k];
      const realObjName = alias[k] || k;
      if (objectsMap[realObjName]) {
        const fields = objectsFields[realObjName];
        if (isPlainObject(value)) {
          // 在遍历对象的时候，固定的虚拟字段不再将里面的字段展平到跟虚拟字段平级，减少冗余属性的生成（多属性复杂对象优化内存）
          const isSimple = !!simpleObjects.find((conf) => (alias[conf] || conf) === k);
          data[k] = transItem({
            json: { object: realObjName, fields, alias },
            objects,
            item: value,
            isSimple,
          });
          data[k] = doTransObjectValue({
            data: data[k],
            objectsFields,
            objectsMap,
            alias,
            objects,
            simpleObjects,
          });
        } else if (isArray(value)) {
          data[k] = value.map((item) => {
            const rst = doTransObjectValue({
              data: { [realObjName]: item },
              objectsFields,
              objectsMap,
              alias,
              objects,
              simpleObjects,
            });
            return rst[realObjName] || data;
          });
        }
      }
    }
  }
  return data;
}

function transItem({ json, objects, item, isSimple }) {
  item = preFormatGqlItem(item); // 先处理调 _aggrerate._count的逻辑
  const { fields = [] } = json;
  const outItem = {};
  fields.forEach((fieldObj) => {
    const field = fieldObj.field || fieldObj;
    const fieldItem = getFieldItem({ field, objects, json });
    const prop = field.replaceAll('.', PROP_SPLIT);
    let itemValue = null;
    if (field.includes('.')) {
      if (!isSimple) {
        const fieldArr = field.split('.');
        itemValue = getCascadeData(fieldArr, item);
        outItem[prop] = itemValue;
      } else {
      }
    } else {
      itemValue = get(item, field, '');
      outItem[prop] = itemValue;
    }
    let valueType = fieldItem?.value_type;
    // 兼容后端 DATETIME 字段设置为 TEXT
    if (fieldItem?.value_type === 'TEXT' && fieldObj?.value_type === 'DATETIME') {
      valueType = fieldObj?.value_type;
    }
    if (['SELECT_ONE', 'SELECT_MANY'].includes(valueType)) {
      const propClass = prop + PROP_CLASS;
      const propLabel = prop + PROP_LABEL;
      const options = get(
        fieldItem,
        valueType === 'SELECT_ONE' ? 'select_one_option.options' : 'select_many_option.options',
        [],
      );
      if ((itemValue || itemValue === 0) && options.length) {
        if (valueType === 'SELECT_ONE') {
          // 一对多和单选值同时生效时返回一个数组
          if (isArray(itemValue)) {
            outItem[propLabel] = itemValue.map((v) => {
              return options.filter((option) => option.value === v)[0]?.label;
            });
            outItem[propClass] = itemValue.map((v) => {
              return options.filter((option) => option.value === v)[0]?.class;
            });
          } else {
            const optionItem = options.filter((option) => option.value === itemValue)[0];
            if (optionItem) {
              outItem[propLabel] = optionItem.label;
              outItem[propClass] = optionItem.class;
            }
          }
        } else {
          if (isArray(itemValue)) {
            const itemListLabel = [];
            const itemListClass = [];
            itemValue.forEach((val) => {
              if (!isString(val) && !isNumber(val)) {
                itemListLabel.push([val]);
                itemListClass.push([val]);
              } else {
                itemListLabel.push(
                  val
                    .split(',')
                    .map((item) => options.filter((option) => option.value === item)[0]?.label),
                );
                itemListClass.push(
                  val
                    .split(',')
                    .map((item) => options.filter((option) => option.value === item)[0]?.class),
                );
              }
            });
            outItem[propLabel] = itemListLabel;
            outItem[propClass] = itemListClass;
          } else {
            outItem[propLabel] = itemValue
              .split(',')
              .map((item) => options.filter((option) => option.value === item)[0]?.label);
            outItem[propClass] = itemValue
              .split(',')
              .map((item) => options.filter((option) => option.value === item)[0]?.class);
          }
        }
      }
    } else if (valueType === 'DATETIME' && itemValue) {
      const propLabel = prop + PROP_LABEL;
      const fieldConfig = fields.filter((item) => item.field === field);
      const format = fieldConfig[0]?.format || FORMAT_TIME;
      outItem[propLabel] = formatTime(new Date(itemValue * 1000), format);
    }
  });
  outItem.id = item.id;
  return { ...outItem, ...item };
}

function getFieldItem({ field, objects, json }) {
  const { objectItem, itemField } = getObjectItemAndField({ field, objects, json });
  if (objectItem) {
    const fieldItem = objectItem.fields.filter((item) => item.name === itemField)[0];
    if (fieldItem) {
      // 后端对于value是int的，加了一个SELECT_ONE_INT
      if (fieldItem.value_type === 'SELECT_ONE_INT') {
        fieldItem.value_type = 'SELECT_ONE';
        fieldItem.select_one_option = fieldItem.select_one_int_option;
      }
    }
    return fieldItem;
  }
  return null;
}

function getObjectItemAndField({ field, objects, json }) {
  const { object } = json;
  let fieldObject = object;
  let itemField = field;
  if (field.includes('.')) {
    const fieldArr = field.split('.');
    const fieldLen = fieldArr.length;
    fieldObject = fieldArr[fieldLen - 2];
    itemField = fieldArr[fieldLen - 1];
  }
  const { alias } = json;
  if (alias) {
    fieldObject = alias[fieldObject] || fieldObject;
  }
  const objectItem = objects?.filter((item) => item?.object?.name === fieldObject)?.[0];
  return {
    objectItem,
    itemField,
  };
}

// 将graphql返回的结果预处理处理_aggregate.count的逻辑，将数据整合到外部，兼容以前的逻辑
function preFormatGqlItem(data) {
  if (isPlainObject(data)) {
    forEach(data, (item, key) => {
      if (item?._data !== undefined) {
        data[key] = item._data || [];
      }
      // 如有聚合数据，打散到对象里边去
      if (item?._aggregate && isArray(data[key])) {
        data[key].forEach((dataItem) => {
          dataItem._aggregate = item._aggregate;
        });
      }
      if (item?._aggregate?.count !== undefined) {
        data[`${key}_aggregate_count`] = item._aggregate?.count;
        delete data[key]._aggregate;
      }
      data[key] && (data[key] = preFormatGqlItem(data[key]));
    });
  } else if (isArray(data)) {
    data = map(data, (item) => preFormatGqlItem(item));
  }
  return data;
}

// 返回的数据层层包含如 department:[{id:}],需要扁平化
function getCascadeData(keyList, data) {
  let sourceData = data;
  keyList.forEach((key) => {
    // 返回key名称里无特殊字开头的_
    if (key[0] === '_') {
      const arr = key.split('');
      arr.shift();
      key = arr.join('');
    }
    // 如果数据源是数组就一项项取值 否则只取一次值
    if (isArray(sourceData)) {
      const next = [];
      sourceData = sourceData.forEach((item) => {
        // 如果数组内某一项是数组就一项项取值 对最后一个key的取值不会有数组无须额外处理
        if (isArray(item)) {
          next.push(...item.map((item2) => item2[key]));
        } else if (isObject(item)) {
          next.push(item[key]);
        }
      });
      // 当a.b.c为一对多对多时 a.b能取到多个数据 当其中所有的b.c都为空时sourceData为只有null的列表 因此next为空列表 需要单独转换为null
      sourceData = next.length !== 0 ? next : null;
    } else if (isObject(sourceData)) {
      sourceData = sourceData[key];
    }
  });
  return sourceData;
}

// 深度遍历对对象数据的字段做处理，注入label和class等
function transObjectValue({ data, json = {}, objects = [] }) {
  const { object: mainObject } = json;
  const objectsFields = getObjectsFields(json);
  const alias = json?.alias || {};
  const simpleObjects = json?.simple_objects;
  const objectsMap = {};
  (objects || []).forEach((obj) => {
    if (obj?.object?.name) {
      objectsMap[obj.object.name] = true;
    }
  });
  const rst = doTransObjectValue({
    data: { [mainObject]: data },
    objectsFields,
    objectsMap,
    alias,
    objects,
    simpleObjects,
  });
  return rst[mainObject] || data;
}

export default {
  getObjectsFields,
  doTransObjectValue,
  transItem,
  getFieldItem,
  getObjectItemAndField,
  preFormatGqlItem,
  getCascadeData,
  transObjectValue,
};
