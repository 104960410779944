//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Dialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '960px',
    },
    height: {
      type: String,
      default: '460px',
    },
    marginTop: {
      type: String,
      default: 'max(0px, calc((100vh - 610px) / 2))',
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    tags: {
      type: Array,
      default: () => {
        return [];
      },
    },
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    bodyStyle() {
      return `height: ${this.height}`;
    },
  },
  watch: {
    visible(n) {
      if (n) {
        this.$nextTick(() => {
          const body = this.$refs.dialog.$el.getElementsByClassName('el-dialog__body')[0];
          body.setAttribute('style', this.bodyStyle);
        });
      }
    },
  },
  methods: {
    close() {
      this.$emit('update:visible', false);
      this.$emit('close');
    },
    opened() {
      this.$emit('opened');
    },
  },
};
