/* eslint-disable prettier/prettier */
import { getPageRoute } from './common';
const spaRoutes = [
  'login',
  'help',
  'setUser',
  ['door', 'login/door', 'login/door'],
  ['strategySetting', 'setUser', 'setUser/strategySetting'],
  ['strategyView', 'setUser/strategyView', 'setUser/strategyView'],
  ['strategyEdit', 'setUser/strategyEdit', 'setUser/strategyEdit'],
  'registerAuth',
  'setUp',
  ['questionnaire_answer_detail', 'setUp/questionnaireDetail', 'questionnaire_answer_detail'],
  ['questionnaire_answer_edit', 'setUp/questionnaireEdit', 'questionnaire_answer_edit'],
  ['staffManagement', 'setUp/staffManagement', 'staffManagement'],
  ['doctorManagement', 'setUp/doctorManagement', 'doctorManagement'], // 医生管理
  ['customerTagSystem', 'setUp/customerTagSystem', 'customerTagSystem'], // 客户标签体系
  ['groupingStrategy', 'setUp/groupingStrategy', 'groupingStrategy'], // 分群策略
  ['groupingStrategyEdit', 'setUp/groupingStrategy/edit', 'groupingStrategy/groupingStrategyEdit'], // 编辑分群策略
  [
    'groupingStrategyEdit',
    'setUp/groupingStrategy/edit',
    'groupingStrategy/groupingStrategyCreate',
  ], // 新增分群策略
  [
    'groupingStrategyDetail',
    'setUp/groupingStrategy/detail',
    'groupingStrategy/groupingStrategyDetail',
  ], // 分群策略详情
  ['listManagement', 'setUp/listManagement', 'listManagement'], // 名单管理
  ['doctorManagementNew', 'setUp/doctorManagementNew', 'doctorManagementNew'],
  ['crm_web_doctor_management_msl', 'crm-web/doctorManagementMSL', 'crm_web_doctor_management_msl'],
  ['crm_web_visit_management_msl', 'crm-web/visitManagementMSL', 'crm_web_visit_management_msl'],
  [
    'crm_web_visit_management_msl_detail',
    'crm-web/visitManagementMSL/detail',
    'crm_web_visit_management_msl_detail',
  ],
  ['crm_dcr/detail', 'dcr/dcrNewDetail', 'crm_dcr/detail'],
  ['esm_dcr/detail', 'dcr/esmSpeakerDcrDetail', 'esm_dcr/detail'],
  [
    'crm_web_visit_management_msl_mutation',
    'crm-web/visitManagementMSL/mutation',
    'crm_web_visit_management_msl_mutation',
  ],
  [
    'crm_web_doctor_management_msl_detail',
    'crm-web/doctorManagementMSL/detail',
    'crm_web_doctor_management_msl_detail',
  ],
  ['crm_web_doctor_management_msl_mutation', 'crm-web/doctorManagementMSL/mutation'],
  ['doctorAllDetail', 'setUp/doctorAllDetail', 'doctorAllDetail'], // 医生详情
  ['hospitalAllDetail', 'setUp/hospitalAllDetail', 'hospitalAllDetail'], // 医院详情
  ['doctorRegist', 'setUp/doctorManagement/doctorRegist', 'doctorRegist'], // 医生注册
  ['doctorInfo', 'setUp/doctorManagement/doctorInfo', 'doctorInfo'],
  ['representManagement', 'setUp/representManagement', 'representManagement'],
  ['representDetaill', 'setUp/representManagement/detaill', 'representDetaill'],
  ['hospitalManagement', 'setUp/hospitalManagement', 'hospitalManagement'],
  ['hospitalManagementNew', 'setUp/hospitalManagementNew', 'hospitalManagementNew'],
  [
    'hospitalDetail',
    'setUp/hospitalManagement/hospitalDetail',
    'setUp/hospitalManagement/hospitalDetail',
  ],
  ['CrmKeyAccountManagement', 'crmKeyAccountManagement', 'CrmKeyAccountManagement'], // 大客户管理列表
  [
    'CrmKeyAccountManagementEdit',
    'crmKeyAccountManagement/mutation',
    'CrmKeyAccountManagementEdit',
  ], // 大客户管理计划新建编辑
  [
    'CrmKeyAccountManagementDetail',
    'crmKeyAccountManagement/detail',
    'CrmKeyAccountManagementDetail',
  ], // 大客户管理计划详情
  [
    'crmTempDisabled',
    'crmTempDisabled',
    'crmTempDisabled',
  ], // 临时禁用列表
  [
    'crmTempDisabledDetail',
    'crmTempDisabled/detail',
    'crmTempDisabledDetail',
  ], // 临时禁用详情
  ['pharmacyManagement', 'setUp/pharmacyManagement', 'pharmacyManagement'], // 药店管理
  ['crmOtcDoctorManagement', 'setUp/crmOtcDoctorManagement', 'crmOtcDoctorManagement'], // 店员管理
  ['CrmOtcHcpDetailWeb', 'setUp/crmOtcDoctorManagement/detail', 'CrmOtcHcpDetailWeb'], // 店员详情
  ['CrmOtcHcpMutationWeb', 'setUp/crmOtcDoctorManagement/mutation', 'CrmOtcHcpMutationWeb'], // 店员编辑
  ['pharmacyStoreDetail', 'setUp/pharmacyStoreDetail', 'pharmacyStoreDetail'],
  ['vistorManagementOtc', 'vistorOtc/list', 'vistorManagementOtc'], // otc拜访
  ['vitsitOtcDetail', 'vistorOtc/detail', 'vitsitOtcDetail'], // otc拜访
  ['vitsitOtcMutation', 'vistorOtc/mutation', 'vitsitOtcMutation'], // otc拜访
  ['vitsitOtcDetailHq', 'vistorOtc/detailHq', 'vitsitOtcDetailHq'], // otc总部拜访
  ['vitsitOtcMutationHq', 'vistorOtc/mutationHq', 'vitsitOtcMutationHq'], // otc总部拜访
  'addressList',
  ['addressList', 'addressList/labelEdit', 'addressList/labelEdit'],
  ['superStaffManagement', 'addressList/staffList', 'superStaffManagement'],
  'protocol',
  'dataCenter',
  'register',
  'articleManagement',
  [
    'articleManagementEdit',
    'articleManagement/articleManagementEdit',
    'articleManagement/articleManagementEdit',
  ],
  [
    'createIntelligentTask',
    'articleManagement/createIntelligentTask',
    'articleManagement/createIntelligentTask',
  ],
  ['articleRecord', 'articleManagement/articleRecord', 'articleManagement/articleRecord'],
  'artilceList',
  ['addArtilce', 'artilceList/addArtilce', 'artilceList/addArtilce'],
  [
    'articleDraftList',
    'articleDraftManagement/articleDraftList',
    'articleDraftManagement/articleDraftList',
  ],
  'rosterList',
  ['rosterDetail', 'rosterList/rosterDetail', 'rosterList/rosterDetail'],
  'examBankManagement', // 题库管理
  ['detailExamBank', 'examBankManagement/detailExamBank', 'examBankManagement/detailExamBank'],
  ['addExam', 'examBankManagement/addExam', 'examBankManagement/addExam'],
  'examPaperManagement',
  'commentManagement',
  'voteManagement',
  ['votingDetail', 'voteManagement/votingDetail', 'voteManagement/votingDetail'],
  ['addVoting', 'voteManagement/addVoting', 'voteManagement/addVoting'],
  // 问卷管理
  'questionnaire',
  ['questionnaire_mutation', 'questionnaire/mutation', 'questionnaire_mutation'],
  ['questionnaire_detail', 'questionnaire/detail', 'questionnaire_detail'],
  'publicSet',
  'setManager',
  'enterprisesSet',
  'outAppSet',
  'operationManagement',
  ['operationManagementEdit', 'operationManagement/edit', 'operationManagement/edit'],
  'materialManagement',
  [
    'material-img-upload',
    'materialManagement/material-img-upload',
    'materialManagement/material-img-upload',
  ],
  [
    'material-audio-upload',
    'materialManagement/material-audio-upload',
    'materialManagement/material-audio-upload',
  ],
  [
    'material-video-upload',
    'materialManagement/material-video-upload',
    'materialManagement/material-video-upload',
  ],
  [
    'material-doc-upload',
    'materialManagement/material-doc-upload',
    'materialManagement/material-doc-upload',
  ],
  ['docDetail', 'materialManagement/docDetail', 'materialManagement/docDetail'],
  'tagSet',
  'tagManagement',
  'articleData',
  [
    'articleDataOfficialAccount',
    'articleData/articleDataOfficialAccount',
    'articleData/articleDataOfficialAccount',
  ],
  'meetingData',
  'nonMeetingFee',
  ['laborPaymentOrder', 'meetingManagement/laborPaymentOrder'],
  ['laborPaymentDetail', 'meetingManagement/laborPaymentDetail', 'laborPaymentDetail'],
  ['createFee', 'nonMeetingFee/createFee', 'nonMeetingFee/createFee'],
  ['feeDetail', 'nonMeetingFee/feeDetail', 'nonMeetingFee/feeDetail'],
  ['meetingDataDetail', 'meetingData/meetingDetail', 'meetingData/meetingDetail'],
  [
    'meetingOrganizersDetail',
    'meetingData/meetingOrganizersDetail',
    'meetingData/meetingOrganizersDetail',
  ],
  'meetingSet',
  'userData', // 用户数据
  'chattingRecords', // 会话留存
  ['conversationView', 'chattingRecords/conversationView', 'chattingRecords/conversationView'], // 会话检索
  ['sensitiveWords', 'chattingRecords/sensitiveWords', 'chattingRecords/sensitiveWords'], // 敏感词
  'enterpriseRedPacket',
  'externalContactManagement',
  [
    'externalContactManagementNew',
    'externalContactManagement/newIndex',
    'externalContactManagementNew',
  ],
  [
    'externalContactManagementRecords',
    'externalContactManagement/records',
    'externalContactManagement/records',
  ],
  'applicationManagement', // 应用管理
  [
    'applicationDetail',
    'applicationManagement/applicationDetail',
    'applicationManagement/applicationDetail',
  ],
  'interestSet', // 兴趣偏好设置
  'agentSet', // 企业微信设置
  'setUnbind', // 解绑设置
  'meetingManagement', // 会议设置
  'eventsMeetingManagement', // 会议管理
  'eventsMeetingDetail',
  'eventsSeriesMeetingPlan', // 系列会
  ['eventsSeriesMeetingDetail', 'eventsSeriesMeetingPlan/detail', 'eventsSeriesMeetingPlan/detail'], // 系列会详情
  ['eventsSeriesMeetingCreate', 'eventsSeriesMeetingPlan/create', 'eventsSeriesMeetingPlan/create'], // 系列会新建编辑
  [
    'eventsSeriesMeetingDcrMutation',
    'eventsSeriesMeetingPlan/dcrMutation',
    'eventsSeriesMeetingPlan/dcrMutation',
  ], // 系列会新建编辑
  'attenderManagement', // 参会人设置
  ['autoReply', 'messageManagement/messageReply', 'autoReply'],
  ['addAutoReply', 'messageManagement/messageReply/addReply', 'addAutoReply'],
  ['MessageList', 'messageManagement/messageList', 'MessageList'],
  ['userMessageList', 'messageManagement/messageList/userMessageList', 'userMessageList'],
  'webSiteMsgManagement', // 站内信管理
  ['myApply', 'approval/myApply', 'myApply'], // 我的申请
  ['myApproval', 'approval/myApproval', 'myApproval'], // 我的审批
  ['myApprovalFinance', 'approval/myApprovalFinance', 'myApprovalFinance'], // 财务我的审批
  ['myApprovalFinanceDetail', 'approval/myApprovalFinanceDetail', 'myApprovalFinanceDetail'], // 财务劳务支付详情
  ['approvalPageDetail', 'approval/approvalPageDetail', 'approvalPageDetail'], // 审批人视角--二级页面审批详情
  ['approvalPageEdit', 'approval/approvalPageEdit', 'approvalPageEdit'], // 审批人视角--二级页面审批单编辑
  ['applyPageDetail', 'approval/applyPageDetail', 'applyPageDetail'], // 申请人视角--二级页面审批详情
  ['applyPageEdit', 'approval/applyPageEdit', 'applyPageEdit'], // 申请人视角--二级页面审批单编辑
  ['approvalProcess', 'approval/approvalProcess', 'approvalProcess'], // 审批流程
  ['articleDraftApproval', 'approval/articleDraftApproval', 'articleDraftApproval'], // 文章草稿审批
  ['add', 'webSiteMsgManagement/add', 'webSiteMsgManagement/add'],
  ['layoutList', 'layout/list', 'layoutList'],
  ['layoutListDemo', 'layout/demo/list', 'layoutListDemo'],
  ['layoutDetail', 'layout/detail', 'layoutDetail'],
  ['layoutTab', 'layout/tab', 'layoutTab'],
  ['layoutMutation', 'layout/mutation', 'layoutMutation'],
  ['speakerHub', 'speaker/speakerHub', 'speakerHub'],
  ['speakerEdit', 'speaker/speakerEdit', 'speakerEdit'],
  ['speakerDetail', 'speaker/speakerDetail', 'speakerDetail'],
  ['speakerClassification', 'speaker/speakerClassification', 'speakerClassification'],
  ['speakerMassUploadData', 'speaker/speakerMassUploadData', 'speakerMassUploadData'],
  'qaManagementForDeputy', // 医学问询-代表
  'qaManagementForHospital', // 医学问询-医学部
  'subProductManagement', // 产品管理 - 管理员
  'productManagement',
  'salesData',
  'salesAppeal',
  'departureManagement', // 离岗活动
  'productDetail', // 产品详情
  'dcr/list',
  ['dcrMutation', 'dcr/mutation'],
  'visitor/list',
  'visitor/mutation',
  'visitor/detail',
  'assistManagement/list',
  'assistManagement/detail',
  'assistManagementForOtc/list',
  'assistManagementForOtc/detail',
  'custom',
  'rame',
  'wujie',
  'feijian', // 飞检
  'visitorManager/list',
  'visitorManager/hospitalDetail',
  'visitorManager/doctorDetail',
  'assistManagement/list',
  'assistManagement/detail',
  ['dataImport', 'dataBatch/dataImport', 'dataImport'],
  ['dataRecord', 'dataBatch/dataRecord', 'dataRecord'],
  ['dataExport', 'dataBatch/dataExport', 'dataExport'],
  ['dataFunc', 'dataBatch/dataFunc', 'dataFunc'],
  'contentList',
  ['editContent', 'contentList/editContent', 'contentList/editContent'],
  ['budgetDetail', 'budgetAndFee/budgetDetail', 'budgetDetail'], // 预算明细
  ['budgetTable', 'budgetAndFee/budgetTable', 'budgetTable'], // 预算表
  ['budgetTableItem', 'budgetAndFee/budgetTable/budgetTableItem', 'budgetTable/budgetTableItem'], // 预算表详情页
  ['feeType', 'budgetAndFee/feeType', 'feeType'], // 费用类型
  ['courseManagementList', 'courseManagement/list', 'courseManagementList'], // 课程管理列表
  ['courseMutation', 'courseManagement/courseMutation', 'courseMutation'], // 课程编辑
  ['courseMenuMutation', 'courseManagement/courseMenuMutation', 'courseMenuMutation'], // 课单编辑
  'presenterManagement', // 内容作者管理
  ['loginLog', 'systemLog/loginLog', 'loginLog'],
  'noticeCenter',
  ['noticeDetail', 'noticeCenter/detail', 'noticeDetail'],
  ['onlineBanRecord', 'meetingManagement/onlineBanRecord', 'meetingManagement/onlineBanRecord'],
  ['createProcess', 'meetingManagement/createProcess', 'meetingManagement/createProcess'],
  ['stepSubmit', 'meetingManagement/stepSubmit', 'meetingManagement/stepSubmit'],
  'batchReportMeetings',
  ['confirmRealCost', 'meetingManagement/confirmRealCost', 'meetingManagement/confirmRealCost'],
  ['createMeeting', 'meetingManagement/createMeeting', 'meetingManagement/createMeeting'],
  'sidebarManagement',
  ['announcementMutation', 'announcement/mutation', 'announcementMutation'],
  ['announcementList', 'announcement/list', 'announcementList'],
  ['announcementReadList', 'announcement/list', 'announcementReadList'],
  'mccp',
  ['mccp_new_list', 'mccp-new/list', 'mccp-new/list'],
  ['mccp_new_detail', 'mccp-new/detail', 'mccp-new/detail'],
  'agreement',
  ['agreementCreate', 'agreement/create', 'agreement/create'],
  ['mccpDetail', 'mccp/detail', 'mccp/detail'],
  ['mccpEdit', 'mccp/edit', 'mccp/edit'],
  ['mccpStaffDetail', 'mccp/staffDetail', 'mccp/staffDetail'],
  'intelligentMarketing/pushTask', // 智能营销 - 推送任务
  'intelligentMarketing/pushTask/detail', // 智能营销 - 推送任务详情
  'sessionRetained',
  'windowPeriod',
  'InterestSetV2',
  'adtagManager',
  'powerOperate',
  'aiBotConfig',
  'aiKnowledge',
  'articleManagementStandard',
  'materialManagementStandard',
  'schedule-manage',
  'holiday-manage',
  'schedule-overview',
  'schedule',
  ['crm_web_visitor_template', 'crmWebVisitorTemplate/list', 'crmWebVisitorTemplate'],
  [
    'crm_web_visitor_template_detail',
    'crmWebVisitorTemplate/detail',
    'crmWebVisitorTemplateDetail',
  ],
  [
    'crm_web_visitor_template_mutation',
    'crmWebVisitorTemplate/mutation',
    'crmWebVisitorTemplateMutation',
  ],
  ['crm_web_visitor_template_apply', 'crmWebVisitorTemplate/apply', 'crmWebVisitorTemplateApply'],
  ['crm_web_product_to_hco', 'crmWebProductToHco/list', 'crmWebProductToHco'],
  ['crm_web_product_to_hco_detail', 'crmWebProductToHco/detail', 'crmWebProductToHcoDetail'],
  ['crm_web_product_to_hco_mutation', 'crmWebProductToHco/mutation', 'crmWebProductToHcoMutation'],
  [
    'crm_web_product_to_hco_task_detail',
    'crmWebProductToHco/taskDetail',
    'crmWebProductToHcoTaskDetail',
  ],
  ['crm_sales_estimation', 'crmSalesEstimation/list', 'crmSalesEstimation'],
  ['crm_sales_estimation_detail', 'crmSalesEstimation/detail', 'crmSalesEstimationDetail'],
  [
    'crm_sales_estimation_manager',
    'crmSalesEstimation/managerList',
    'crmSalesEstimationManagerList',
  ],
  'noPermission',
  'mediaLibrary',
  ['mediaLibraryRecord', 'mediaLibrary/record', 'mediaLibrary/record'],
  ['mediaLibraryVideoPlay', 'mediaLibrary/play', 'mediaLibrary/play'],
  ['mediaLibraryDocpreview', 'mediaLibrary/docpreview', 'mediaLibrary/docpreview'],
  'externalLinkPageRegistration',
  'hcpTagManager', // 数据中心 - 标签管理
  'hcpTagManager/detail', // 数据中心 - HCP标签详情
  'postAgent', // 岗位代理
  'iframe',
  'usageFeedback', // 用户反馈
  'feedbackProcessing', // 反馈处理
  'demoElement', // demo页
  'demoLayout',
].map((r) => (typeof r === 'string' ? getPageRoute([r]) : getPageRoute(r)));
export default spaRoutes;